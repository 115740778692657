import React from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

function App() {
  return (
    <Container className="container px-lg-5">
      <Row className="justify-content-md-center">
        <Col> <h4>Dashboard</h4></Col>
      </Row>
      <Row>
        <Col className="py-3 px-lg-5  bg-light">1 of 3</Col>
        <Col className="py-3 px-lg-5  bg-primary">2 of 3</Col>
        <Col className="py-3 px-lg-5  bg-secondary">3 of 3</Col>
        <Col className="py-3 px-lg-5  bg-light">4 of 4</Col>
      </Row>
      <Row>
        <Col className="py-3 px-lg-5  bg-light">1 of 3</Col>
        <Col className="py-3 px-lg-5  bg-primary">2 of 3</Col>
        <Col className="py-3 px-lg-5  bg-secondary">3 of 3</Col>
        <Col className="py-3 px-lg-5  bg-light">4 of 4</Col>
      </Row>
    </Container>
  );
}

export default App;
